
import ResetPassword from "~/graphql/Authentication/ResetPassword.gql";
export default {
  layout: "login",
  data() {
    return {
      password1: "",
      password2: "",
      passwordError: false,
      passwordErrorText: "",
      passwordChanged: false,
      passwordChangeLoading: false,
    };
  },
  computed: {
    token() {
      return this.$route.params.id;
    },
    isSamePassword() {
      return this.password1 != "" && this.password1 === this.password2;
    },
    isPasswordLengthValid() {
      return this.password1.length >= 8;
    },
    hasUppercaseLetter() {
      return /[A-Z]/.test(this.password1);
    },
    hasLowercaseLetter() {
      return /[a-z]/.test(this.password1);
    },
    hasNumber() {
      return /[0-9]/.test(this.password1);
    },
    isValidPassword() {
      return (
        this.isSamePassword &&
        this.isPasswordLengthValid &&
        this.hasUppercaseLetter &&
        this.hasLowercaseLetter &&
        this.hasNumber
      );
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.goToLogin();
    }
  },

  methods: {
    goToLogin() {
      this.$router.push("/login");
    },

    goToForgotPassword() {
      this.$router.push("/forgot-password");
    },

    passwordValidator(s) {
      return s === this.password1;
    },

    logPasswordChange(status, message) {
      this.$amplitude.resetPassword({
        status: status,
        message: message,
      })
    },

    async changePassword() {
      this.passwordError = false;
      this.passwordErrorText = "";
      this.passwordChanged = false;

      if (this.password1 !== this.password2) {
        this.passwordError = true;
        this.passwordErrorText = this.$t("PS_DOES_NOT_MATCH");
        this.passwordChanged = false;
        return;
      }

      if (
        !this.password1.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
        )
      ) {
        this.passwordError = true;
        this.passwordErrorText = this.$t("PS_REQUIREMENTS_NOT_FULFILLED");
        this.passwordChanged = false;
        return;
      }

      this.passwordChangeLoading = true;

      await this.$apollo
        .query({
          query: ResetPassword,
          variables: { token: this.token, password: this.password1 },
        })
        .then(() => {
          this.logPasswordChange("SUCCESS", "Password changed successfully");
          this.passwordChanged = true;
        })
        .catch((err) => {
          this.logPasswordChange("ERROR", err.message);
          this.passwordError = true;
        });

      this.passwordChangeLoading = false;
    },
  },
};
